<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="detailed">
            <img :src="booksDetails.picUrl" alt="" />
            <div>
                <div class="title">
                    <span>{{ booksDetails.name }}</span>
                </div>
                <div class="author">
                    <span>作者: {{ booksDetails.author }}</span>
                </div>
                <div class="time">
                    <span>发布时间: {{ booksDetails.createTime }}</span>
                </div>
                <div class="content">
                    <span>{{ booksDetails.bookDesc }}</span>
                </div>
                <div class="download" @click="downloadBook"><span>下载</span></div>
            </div>
        </div>
    </div>
</template>

<script>
import { getBooksDetails } from '@/api';
export default {
    data() {
        return {
            booksDetails: {},
			nav: [
			    {
			        title: '首页',
			        to: { path: '/' },
			    },
			    {
			        title: '书籍详情',
			    },				
			],
        };
    },

    created() {
        this.getBooksDetails();
    },

    methods: {
        getBooksDetails() {
            const res = getBooksDetails(this.$route.query.id).then((res) => {
                console.log(res);
                this.booksDetails = res.data;
            });
            console.log(res);
        },
		downloadBook(){
			if(this.booksDetails.contentUrl){
				window.open(this.booksDetails.contentUrl)
			} else {
				this.$message.warning('暂无下载链接')
			}
		}
    },
};
</script>

<style lang="less" scoped>
.position {
    margin-top: 24px;

    span {
        font-size: 16px;
        line-height: 23px;

        color: #666666;
    }
}
.detailed {
    margin-top: 24px;
    width: 100%;
    display: flex;

    img {
        height: 253px;
        width: 16%;
        margin-right: 24px;
        border-radius: 2px;
        box-shadow: 0.5px 0.5px 2px rgba(103, 102, 102, 0.9);
    }
}

.title {
    margin-bottom: 30px;

    span {
        font-size: 24px;
        font-weight: bold;

        line-height: 35px;

        color: #333333;
    }
}

.author {
    margin-bottom: 8px;

    span {
        font-size: 16px;
        line-height: 23px;
        color: #666666;
    }
}

.time {
    margin-bottom: 8px;

    span {
        font-size: 16px;
        line-height: 23px;
        color: #666666;
    }
}

.content {
    height: 46px;
    width: 100%;

    span {
        height: 45px;
        width: 100%;
        line-height: 23px;
        // word-wrap: break-word;
        font-size: 16px;
        color: #666666;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

.download {
    display: flex;
    margin-top: 36px;
    //width: 20%;
    width: 200px;
    background-color: #eda200;
    border-radius: 4px;
    height: 44px;

    align-items: center;
    box-shadow: 0.5px 0.5px 2px rgba(103, 102, 102, 0.9);

    span {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
    }
}
</style>
